import React, { useContext } from "react";
import { LoadingGifContext } from "../App";
const FlymyaIconLoading = (props) => {
  const gifloading = useContext(LoadingGifContext);
  return (
    <div className="loadingGifDiv">
      <div
        className="loadingGifDivInner"
        style={gifloading ? { backgroundImage: `url("${gifloading}")` } : {}}
      >
        &nbsp;
        {props?.loadingMessage && (
          <p className="loadingMessage">{props?.loadingMessage}</p>
        )}
      </div>
    </div>
  );
};

export default FlymyaIconLoading;
