import React, { useEffect, useState } from "react";
import { Grid, InputAdornment, IconButton, TextField } from "@material-ui/core";
import { useParams } from "react-router-dom";
import {
  checkInApp,
  checkInWeb,
  getClientTag,
  getLocale,
} from "../../utils/checkurl";
import PageHeader from "../PageHeader";
import HotelSortDropdown from "../../components/hotel/HotelSortDropdown";
import SearchIcon from "@material-ui/icons/Search";
import "../../assets/scss/HotelLongStayResult.scss";
import "../../assets/scss/HotelCommon.scss";
import HotelLongItem from "./HotelLongItem";
import clientV3 from "../../apolloClient";
import { GET_LONG_STAY_HOTEL } from "../../queries/hotelquery";
import useFlymyaSessionHook from "../../hooks/FlymyaSessionHook";
import HomeMenu from "../HomeMenu";
import { MY_MM } from "../../config/constants";
import { useTranslation } from "react-i18next";
import { isAuthenticated } from "../../lib/auth";
import { IS_CUSTOMER_VAL } from "../../config/typecheck";

const HotelLongStayResult = (props) => {
  const { locale } = useParams();
  const [selectedLanguage] = useState(getLocale(locale));
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const [longStayHotel, setLongStayHotel] = useState([]);
  const [filteredHotels, setFilteredHotels] = useState([]);
  const [sortByValue, setSortByValue] = useState("default");
  const [searchQuery, setSearchQuery] = useState("");
  const [myanmarFont] = useState(
    getLocale(locale) === MY_MM ? "myanmarFont" : "",
  );
  const [clientTag] = useState(getClientTag(window.location.search));
  const [selectedProduct, setSelectedProduct] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const { t } = useTranslation();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const { handleFlymyaSession, handleCreateUserAction } =
    useFlymyaSessionHook();

  useEffect(() => {
    handleFlymyaSession();
    handleCreateUserAction({ eventName: "homepage_load" });
    handleCreateUserAction({ eventName: "hotel_long_homepage_load" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clientV3(
      !isAuthenticated()
        ? "graphql/guest"
        : isAuthenticated() && IS_CUSTOMER_VAL
        ? "graphql/customer"
        : "graphql",
    )
      .query({
        query: GET_LONG_STAY_HOTEL,
        fetchPolicy: "network-only",
      })
      .then((res) => {
        setLongStayHotel(res.data.longStayHotels);
        setFilteredHotels(res.data.longStayHotels);
      });
  }, []);

  useEffect(() => {
    setFilteredHotels(
      longStayHotel.filter((hotel) =>
        hotel.hotel_name.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    );
  }, [searchQuery]);

  const sortViaPrice = (val) => {
    setSortByValue(val);

    let updatedHotels = [...filteredHotels]; // Make a shallow copy of the array

    if (val === "highest_price" || val === "default") {
      updatedHotels.sort((a, b) => {
        if (b.price) {
          return b.price - a.price;
        }
        return 0;
      });
    }

    if (val === "lowest_price") {
      updatedHotels.sort((a, b) => {
        if (b.price) {
          return a.price - b.price;
        }
        return 0;
      });
    }

    setFilteredHotels(updatedHotels); // Update the state with the sorted array
  };

  return (
    <div className="HotelLongStayResult">
      {/* <PageHeader
        pageName="HotelLongStayResult"
        selectedLanguage={selectedLanguage}
        pageType=""
        pathName={props.location}
        isInApp={false}
        isInWeb={isInWeb}
        gorgiasInfo={{
          roomName: null,
          //   currency: currency,
          //   checkIn: checkIn,
          //   checkOut: checkOut,
          minPriceToShow: null,
          hotelName: null,
          phoneNumber: null,
        }}
        showLogin={true}
      /> */}
      <HomeMenu
        props={props}
        isInApp={isInApp}
        isInWeb={isInWeb}
        myanmarFont={myanmarFont}
        selectedLanguage={selectedLanguage}
        clientTag={clientTag}
        toggleDrawer={() => setShowMenu(!showMenu)}
        showMenu={showMenu}
        pageName="HotelLongStayResult"
        permissionArr={localStorage.getItem("permissionArr")}
        setSelectedProduct={setSelectedProduct}
        selectedProduct={selectedProduct}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Grid container item md={12} lg={10} className="hotel-long-main">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginBottom: "15px" }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className="long-stay-search"
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <span className="title">
                  {t("hotel.property_name") !== "hotel.property_name"
                    ? t("hotel.property_name")
                    : "Property Name"}
                </span>
                <TextField
                  label=""
                  onChange={(e) => {
                    handleSearchChange(e);
                  }}
                  onKeyPress={(e) => {}}
                  value={null}
                  placeholder={"Search"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon onClick={() => {}} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span className="title">
                  {t("2Search.sort_by") !== "2Search.sort_by"
                    ? t("2Search.sort_by")
                    : "Sort By"}
                </span>
                <HotelSortDropdown
                  sortByValue={sortByValue}
                  handleSortBy={(e) => {
                    sortViaPrice(e.target.value);
                  }}
                />
              </div>
            </Grid>
          </div>
          {filteredHotels.map((hotel) => {
            // if (
            //   hotel?.rooms[0]?.flymya_price[0].sp === 0 &&
            //   hotel?.rooms[0]?.flymya_price[0]?.dr === 0
            // ) {
            //   return null;
            // } else {
            return (
              <HotelLongItem
                key={hotel.hotel_id}
                hotel={hotel}
                selectedLanguage={selectedLanguage}
                isInApp={isInApp}
                isInWeb={isInWeb}
              />
            );
            // }
          })}
        </Grid>
      </div>
    </div>
  );
};

export default HotelLongStayResult;
