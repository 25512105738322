//import { ApolloClient } from "apollo-boost";
import { ApolloClient, InMemoryCache } from "@apollo/client";
//import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";
import { AUTH_TOKEN, TOKEN_TYPE } from "../config/constants";

export const httpLinkV3 = new createHttpLink({
  uri: process.env.REACT_APP_BE_SERVER + "/graphql",
});
export const httpLinkV3Upload = new createUploadLink({
  uri: process.env.REACT_APP_BE_SERVER + "/graphql",
});

export const authLink = setContext((_, { headers }) => {
  const tokenType = localStorage.getItem(TOKEN_TYPE);
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `${tokenType} ${token}` : "",
    },
  };
});

export const clientV3 = new ApolloClient({
  link: authLink.concat(httpLinkV3),
  cache: new InMemoryCache(),
});
export const clientV3Upload = new ApolloClient({
  link: authLink.concat(httpLinkV3Upload),
  cache: new InMemoryCache(),
});
